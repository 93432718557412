import React from "react";
import './App.css';
import AttacksChart from "./components/AttacksChart";
import RankingChart from "./components/RankingChart";

import TrafficChart from "./components/TrafficChart";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <h3>Layer 3 DDOS Attacks over 30 days</h3>
        <AttacksChart />
        <h3>Rankings of the most popular domains over 30 days with change</h3>
        <RankingChart />
        <h3>Traffic change over 30 days HTTP v/s Total</h3>
        <TrafficChart />
      </div>
    );
  }
}

export default App;
