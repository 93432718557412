import React from 'react';
import routes from '../routes';
import axios from 'axios';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend } from 'recharts';
import { Tooltip } from '@mui/material';

class TrafficChart extends React.Component {
    state = {
        data: []
    };

    transformData (rawdata) {
        const search = "'";
        const replaceWith = '"';
        const result = rawdata.split(search).join(replaceWith);
        const data = JSON.parse(result);

        let http_timestamps = [];
        for (let i = 0; i < 720; i++) {
            const http_d = {
                timestamp: data.data.http.timestamps[i],
                http_value: data.data.http.values[i],
                total_value: data.data.total.values[i]
            }
            http_timestamps.push(http_d);
        }
        return http_timestamps;
    };

    fetchTraffic = () => {
    axios
        .get(routes.traffic_change)
        .then(response => {
            const transformData = this.transformData(response.data);
            this.setState({ data: transformData });
        })
        .catch(err => {
            console.log(err);
            return null;
        });
    };

    componentDidMount() {
        this.fetchTraffic();
    }

    render() {
        return (
            <div style={{alignCenter: true, margin: 'auto', border: '3px solid blue', alignItems: 'center', width: '70%'}}>            
            <LineChart width={600} height={300} data={this.state.data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="timestamp" />
                <YAxis domain={['dataMin', 'dataMax']} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="http_value" stroke="#8884d8" dot={false}/>
                <Line type="monotone" dataKey="total_value" stroke="#82ca9d" dot={false}/>
            </LineChart>
            </div>
        );
    }
}

export default TrafficChart