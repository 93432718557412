import React from 'react';
import routes from '../routes';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MinimizeIcon from '@mui/icons-material/Minimize';

class RankingChart extends React.Component {
    state = {
        data: []
    };

    transformData (rawdata) {
        let rankings = [];
        for (let i =0; i < rawdata.length - 1; i++) {
            const search = "'";
            const replaceWith = '"';
            const result = rawdata[i].split(search).join(replaceWith);
            rankings.push(JSON.parse(result));
        }
        return rankings;
    }

    fetchRankings = () => {
        axios
            .get(routes.popular_domains)
            .then(response => {
                const transformedData = this.transformData(response.data["rankingEntries"])
                this.setState({ data: transformedData });
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    componentDidMount() {
        this.fetchRankings();
    }

    render() {
        const tableClass = {tableRow: {
            hover: {
                "&$hover:hover": {
                    backgroundColor: '#49bb7b',
                },
            },
        }};
        return (
        
        <div style={{alignCenter: true, margin: 'auto', border: '3px solid blue', alignItems: 'center', width: '70%'}}>
            <TableContainer component={Paper}>
                <Table className={tableClass.tableRow} sx={{ minWidth: 550 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Domain</TableCell>
                        <TableCell align="left">Current Rank</TableCell>
                        <TableCell align="left">Change in Rank</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.data.map((row) => (
                        <TableRow
                        key={row.domain}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell align="left">{row.domain}</TableCell>
                        <TableCell component="th" scope="row">
                            {row.rank}
                        </TableCell>
                        
                        <TableCell align="left">
                            {row.rankChange != null ? row.rankChange < 0 ? <ArrowDownwardIcon/> : row.rankChange > 0  ?  <ArrowUpwardIcon /> : <MinimizeIcon /> : null}
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>);
    }
}

export default RankingChart;