import React from 'react';
import routes from '../routes';
import axios from 'axios';
import { LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';

class AttacksChart extends React.Component {
    state = {
        data: []
    };

    transformData (rawdata) {
        const search = "'";
        const replaceWith = '"';
        const result = rawdata.split(search).join(replaceWith);
        const data = JSON.parse(result);
        let http_timestamps = [];
        for (let i = 0; i < 720; i++) {
            const http_d = {
                timestamp: data.data.timestamps[i],
                value: data.data.values[i],
            }
            http_timestamps.push(http_d);
        }
        return http_timestamps;
    }

    fetchRankings = () => {
        axios
            .get(routes.attack_layer)
            .then(response => {
                const transformedData = this.transformData(response.data);
                this.setState({ data: transformedData });
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    };

    componentDidMount() {
        this.fetchRankings();
    }

    render() {
        return (<div style={{alignCenter: true, margin: 'auto', border: '3px solid blue', alignItems: 'center', width: '70%'}}>
        <LineChart width={600} height={300} data={this.state.data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false}/>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="timestamp" />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Legend />
            <Tooltip />
            </LineChart>
        </div>);
    }
}

export default AttacksChart;